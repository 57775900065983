import { noop } from 'lodash';

/** @type {import ('./EspMarkdown').EspMarkdownProps} */
const EspMarkdownDefaultProps = {
  avoidFollowLinks: false,
  breakOnNewLine: false,
  customComponents: {},
  globalVariables: null,
  globalsAsUpperCase: false,
  isBarista: undefined,
  isScoped: false,
  loadKbArticle: noop,
  noAnchorLinks: false,
  noAnchorTag: false,
  noExternalLinks: false,
  noInternalLinks: false,
  noMarkdownEmphasis: false,
  noMarkdownLinks: false,
  noReplaceNewLines: false,
  openInternalLinkFromBarista: noop,
  paramValues: null,
  removeTenantHost: false,
  supportedNodes: [
    'autoLink',
    'code',
    'codeblock',
    'codefence',
    'block',
    'inline',
    'image',
    'text',
    'break',
    'breakline',
    'thematicBreak',
    'blockquote',
    'delete',
    'paragraph',
    'heading',
    'strong',
    'link',
    'emphasis',
    'underline',
    'list',
    'listItem',
    'newline',
  ],
  text: '',
};

export default Object.freeze(EspMarkdownDefaultProps);
