// generated imports
import zh_hk from '../../lang/zh-hk.json';
import zh_cn from '../../lang/zh-hans.json';
import zh_tw from '../../lang/zh-tw.json';
import cs from '../../lang/cs.json';
import nl from '../../lang/nl.json';
import en_gb from '../../lang/en-gb.json';
import en_us from '../../lang/en-us.json';
import fi from '../../lang/fi.json';
import fr from '../../lang/fr.json';
import de from '../../lang/de.json';
import hu from '../../lang/hu.json';
import it from '../../lang/it.json';
import ja from '../../lang/ja.json';
import ko from '../../lang/ko.json';
import pl from '../../lang/pl.json';
import pt from '../../lang/pt.json';
import pt_br from '../../lang/pt-br.json';
import ru from '../../lang/ru.json';
import sl from '../../lang/sl.json';
import es from '../../lang/es.json';
import es_mx from '../../lang/es-mx.json';
import sv from '../../lang/sv.json';
import ta from '../../lang/ta.json';
import uk from '../../lang/uk.json';
import vi from '../../lang/vi.json';
// generated locales object goes here
const locales = {
  zh_hk: zh_hk,
  zh_cn: zh_cn,
  zh_tw: zh_tw,
  cs: cs,
  nl: nl,
  en_gb: en_gb,
  en_us: en_us,
  'en-us': en_us, // TODO we need to add this to the script ....... needed to support proper fallback in tests
  fi: fi,
  fr: fr,
  de: de,
  hu: hu,
  it: it,
  ja: ja,
  ko: ko,
  pl: pl,
  pt: pt,
  pt_br: pt_br,
  ru: ru,
  sl: sl,
  es: es,
  es_mx: es_mx,
  sv: sv,
  ta: ta,
  uk: uk,
  vi: vi,
};
export default locales;
