import { persistentStorage, PersistentStorageKeys } from 'esp-util-auth';
import _ from 'lodash';

// Reducer store
import store from '../stores/store';
// Actions
import toastNotificationsActions from '../actions/toastNotificationsActions';
import errorsActions from '../actions/errorsActions';
// Utils
import fakeAPIcall from './fakeAPIcall';
import browserHistory from './browserHistory';
import uiPathGenerator from './uiPathGenerator';
import endpointGenerator from './endpointGenerator';

import XHRUtil from 'esp-util-api';

// widget url params used in portal page
// const PARAM_OTC = 'otc';
// const PARAM_VERSION = 'chat_version';

/**
 * Normalizes API error from SuperAgent as a plain JS Object
 */
const normalizeAPIError = (error, urlId) => {
  const { response } = error;

  const normalizedError = {};
  normalizedError.isTimeout = Boolean(error.timeout);
  normalizedError.isNetworkError = !response && !normalizedError.isTimeout;

  if (response) {
    normalizedError.status = response.status;
    normalizedError.plainResponse = response.text;
    normalizedError.parsedResponse = response.body;

    if (response.body && response.body.errors) {
      // Good formatted message
      normalizedError.errors = response.body.errors;
      if (response.status === 400) {
        // Error 400 from a field or form - Need to pass it to the errorsApi reducer
        normalizedError.fieldError = true;
        normalizedError.errors.forEach((err) => {
          err.id = urlId;
        });
      }
    } else if (response.body) {
      // Wrong formatted message
      normalizedError.errorFormat = true;
      if (window && window.Rollbar && window.Rollbar.error) {
        window.Rollbar.error('improperly formatted by the API', response);
      }
    }
  } else {
    normalizedError.status = null;
    normalizedError.plainResponse = null;
    normalizedError.parsedResponse = null;
  }

  return normalizedError;
};

/**
 * Return an urlId from an url
 * @param url {string} like 'https://qa.dev.espressive.com/api/espuser/v0.1/users'
 * @returns {string} the url become 'espuser.v0.1.users'
 */
const buildUrlId = (url = '') => {
  const apiServer = endpointGenerator.getApiServer();
  const splitUrl = url.split(apiServer);
  if (splitUrl.length >= 1) {
    const [, secondSegment] = url.split(apiServer);
    if (secondSegment) {
      const idUrl = secondSegment.split('/'); // Split the second segment of the url
      _.pull(idUrl, '', 'api'); // Remove empty entries and entries which contains "api"

      return idUrl.join('.');
    }

    return url;
  }

  return '';
};

/**
 * @param error Error returned by SuperAgent library
 * @param normalizedError Object returned by normalizeAPIError
 */
const logAPIError = (error, normalizedError, xRequestID) => {
  // This setting is configured in RollbarConfig.js
  // but in case you need to disable this other purposes...
  // window.Rollbar.configure({enabled: false});

  if (window.Rollbar) {
    const extra = {
      error,
      normalizedError,
      xRequestID,
    };

    window.Rollbar.error('app endpoint error', extra);
  }
};

/**
 * Handle Errors to display the snackbar notification or set to the errors reducer
 * @param wrongFormat {object} should contain title and message of the error when the error is improperly formatted
 * @param fieldError {array} should contains a prpers formatted errors message
 * @param arrayError {array} array of proper formatted error with status different to 400
 */
const handleErrorsNotification = (
  errorMessage,
  fieldError,
  arrayError,
  isToastEnabled
) => {
  // Display snackbar notification only when it's not a 400 error from field
  if (arrayError && isToastEnabled) {
    arrayError.forEach((err) => {
      const clickHandler = errorMessage ? errorMessage.clickHandler : null; // Fix Unit test
      store.dispatch(
        toastNotificationsActions.error({
          clickHandler,
          message: err.message.toString(), // prevents this from blowing up if message is not a string
          title: err.title,
        })
      );
    });
  }
  if (errorMessage && isToastEnabled) {
    store.dispatch(
      toastNotificationsActions.error({
        clickHandler: errorMessage.clickHandler,
        message: errorMessage.message,
        title: errorMessage.title,
      })
    );
  }
  if (fieldError) {
    store.dispatch(errorsActions.setErrors(fieldError));
  }
};

/**
 * Pops up a normalized API error
 */
const showAPIError = (error) => {
  const arrayError = [],
    errorMsg = {};

  if (error.isTimeout) {
    errorMsg.title = 'Connection Timeout';
    errorMsg.message = 'Try again later.';
  } else if (error.isNetworkError) {
    errorMsg.title = 'Network Failure';
    errorMsg.message = 'Try again later.';
  } else if (error.status === 502) {
    errorMsg.title = 'Network Failure';
    errorMsg.clickHandler = {
      action: 'There was an error, please click this message to reload.',
      click: () => browserHistory.push('/'),
    };
  } else if (error.status === 404) {
    errorMsg.title = error.status;
    errorMsg.message = 'Not found';
  } else {
    if (error.errors && error.errors.length > 0) {
      // Any error with the good formatted message
      error.errors.forEach((err) => {
        arrayError.push({
          message: err.message,
          title: error.status,
        });
      });
    } else {
      errorMsg.title = error.status;
      errorMsg.message = ' SEE CONSOLE ';
    }
  }

  const errorMessage = errorMsg.title || errorMsg.message ? errorMsg : null;

  const fieldError = error.fieldError ? error.errors : null;

  const arrayErrors = arrayError.length > 0 ? arrayError : null;

  // send to error msg to the snackbar or the errorsAPI reducer
  handleErrorsNotification(
    errorMessage,
    fieldError,
    arrayErrors,
    error.isToastEnabled
  );
};

const onAnyError = (err, res, requestOptions) => {
  // For some reason on local this error returns a CORS issue and is making noise, I will compare against endpoint/id instead of status code
  if (
    err &&
    err.url &&
    /\/api\/places\/v0\.1\/locations\/1|1626/.test(err.url)
  ) {
    return;
  }

  const errorStatus = err.status;

  // On a 401 error (UNAUTHORIZED), redirect to login and do not display an error popup.
  if (errorStatus === 401 && !requestOptions.preventKickOut) {
    const url = new URL(window.location.href);
    const urlSearchParam = new URLSearchParams(url.search);

    const isIframeWidget = window.self !== window.top;
    const isWidgetClient = urlSearchParam.get('client') === 'widget';

    const currentPath = url.pathname;
    const isPortalPath = currentPath.includes('portal');
    const isLoginPath = currentPath.includes('login');

    const isPortalWidget = isIframeWidget && isPortalPath;
    const isWidgetLogin = !isIframeWidget && isLoginPath && isWidgetClient;

    if (isPortalWidget || isWidgetLogin) {
      // If we are in the portal-widget login-widget context, we need to clear the session and reload the page
      // to avoid the widget to be redirected to the login page or login to lose url params
      persistentStorage.remove(PersistentStorageKeys.SESSION);
      window.location.reload();
    } else {
      // tell logout page it shouldn't perform backend logout since token is invalid
      const logoutUrl = `${uiPathGenerator.genPath('logout')}?isLocalOnly=`;
      browserHistory.push(logoutUrl);
    }
  } else if (errorStatus === 403 && !requestOptions.preventKickOut) {
    // on 403 error (FORBIDDEN) redirect the user to home
    browserHistory.push(uiPathGenerator.genPath('app'));
  } else if (errorStatus === 410) {
    // on 410 error redirect the user to home when he is trying to restart WF1
    browserHistory.push(uiPathGenerator.genPath('app'));
  } else {
    const errorId = buildUrlId(requestOptions.url);
    const normalizedError = normalizeAPIError(err, errorId);
    normalizedError.isToastEnabled = !requestOptions.preventShowError;

    if (normalizedError.isToastEnabled || errorStatus === 502) {
      let xRequestID;
      if (res && res.xhr && res.xhr.getResponseHeader) {
        xRequestID = res.xhr.getResponseHeader('X-Request-ID'); // X-Request-ID
      }
      logAPIError(err, normalizedError, xRequestID);
    }

    showAPIError(normalizedError);
  }
};

const onAnySuccess = (res, requestOptions) => {
  // No error - reset all errors with this errorId
  const errorId = buildUrlId(requestOptions.url);
  store.dispatch(errorsActions.resetErrors(errorId));
};

const APIcall = new XHRUtil({
  onAnyError: onAnyError,
  onAnySuccess: onAnySuccess,
  store: store,
});

const _APIcall =
  process && process.env && process.env.NODE_ENV === 'test'
    ? fakeAPIcall
    : APIcall;

export default _APIcall;
